import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const [gymFactorOpen, setGymFactorOpen] = useState(false)
    const [settingsOpen, setSettingsOpen] = useState(false)

    const changeGymFactorOpen = () => {
        setGymFactorOpen(!gymFactorOpen)
    }
    const changeSettingsOpen = () => {
        setSettingsOpen(!settingsOpen)
    }
    return (
        <nav className='p-[15px] lg:p-[20px] w-full'>
            <div className='flex flex-col'>
                <Link to="/dashboard" className={`no-underline cursor-pointer block py-[15px] text-[15px] ${currentPath === '/dashboard' ? 'text-color_black font-bold' : 'text-gray-500'} hover:text-color_black border-solid border-[1px] border-x-0 border-t-0 border-gray-100`}>
                    DASHBOARD
                </Link>
                <div className={`block py-[15px] border-solid border-[1px] border-x-0 border-t-0 border-gray-100`}>
                    <div onClick={changeGymFactorOpen} className={`cursor-pointer text-[15px] ${currentPath === '/editor' || currentPath === '/requests' || currentPath === '/autoreview' ? 'text-color_black font-bold' : 'text-gray-500'} hover:text-color_black`}>GYMFACTOR</div>
                    {gymFactorOpen &&
                        <div className='flex flex-col'>
                            <Link to="/editor" className={`no-underline cursor-pointer block pt-[20px] pl-[30px] text-[15px] ${currentPath === '/editor' ? 'text-color_black font-bold' : 'text-gray-500'} hover:text-color_black`}>
                                • Editor
                            </Link>
                            <Link to="/requests" className={`no-underline cursor-pointer block pt-[10px] pl-[30px] text-[15px] ${currentPath === '/requests' ? 'text-color_black font-bold' : 'text-gray-500'} hover:text-color_black`}>
                                • Requests
                            </Link>
                            <Link to="/autoreview" className={`no-underline cursor-pointer block pt-[10px] pl-[30px] text-[15px] ${currentPath === '/autoreview' ? 'text-color_black font-bold' : 'text-gray-500'} hover:text-color_black`}>
                                • Auto Review
                            </Link>
                            <Link to="/userreviews" className={`no-underline cursor-pointer block pt-[10px] pl-[30px] text-[15px] ${currentPath === '/userreviews' ? 'text-color_black font-bold' : 'text-gray-500'} hover:text-color_black`}>
                                • User Reviews
                            </Link>
                        </div>
                    }
                </div>
                <div className={`block py-[15px] border-solid border-[1px] border-x-0 border-t-0 border-gray-100`}>
                    <div onClick={changeSettingsOpen} className={`cursor-pointer text-[15px] ${currentPath === '/webapp' || currentPath === '/swagger' || currentPath === '/hangfire' ? 'text-color_black font-bold' : 'text-gray-500'} hover:text-color_black`}>SETTINGS</div>
                    {settingsOpen &&
                        <div className='flex flex-col'>
                            <Link to="/webapp" className={`no-underline cursor-pointer block pt-[20px] pl-[30px] text-[15px] ${currentPath === '/webapp' ? 'text-color_black font-bold' : 'text-gray-500'} hover:text-color_black`}>
                                • Web App
                            </Link>
                            <Link to="/swagger" className={`no-underline cursor-pointer block pt-[20px] pl-[30px] text-[15px] ${currentPath === '/swagger' ? 'text-color_black font-bold' : 'text-gray-500'} hover:text-color_black`}>
                                • Swagger (Dev)
                            </Link>
                            <Link to="/hangfire" className={`no-underline cursor-pointer block pt-[20px] pl-[30px] text-[15px] ${currentPath === '/hangfire' ? 'text-color_black font-bold' : 'text-gray-500'} hover:text-color_black`}>
                                • Hangfire (Dev)
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </nav>
    );
}

export default Sidebar;
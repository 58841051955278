import React from 'react';

const SettingsSwaggerPage = () => {
    return (
        <div className='flex flex-col w-full'>
            <div className='flex flex-col mb-[30px]'>
                <h3 className='text-black mb-[10px]'>Swagger (Dev)</h3>
            </div>
            <iframe src="https://api.beta.hotelgyms.com/swagger/index.html" width='100%' height='100%' title="Swagger (Dev) | HotelGyms.com"></iframe>
        </div>
    );
};

export default SettingsSwaggerPage;
import styled from 'styled-components';
import CustomMedia from './CustomMedia';

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon-style{
    display: flex;
    position: absolute;
    padding-right: 12px;
    align-self: center;
    justify-content: flex-end;
  }
  .label-style{
    margin: 0 3px 0 10px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    color: #333;
  }
  .clickable-label{
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    color: black;
    color: #0098C9;
    text-decoration: underline;
  }
  // .clickable-label:hover{
  //   color: #0098C9;
  //   text-decoration: underline;
  // }
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const CheckMarkWhite = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
  position: absolute;
  width: 14px;
  height: 14px;
`

export const CheckMarkBlue = styled.svg`
  fill: none;
  stroke: #007AA3;
  stroke-width: 3px;
  position: absolute;
  width: 21px;
  height: 21px;
`

export const CheckMarkBlueLight = styled.svg`
  fill: none;
  stroke: #2BC6F8;
  stroke-width: 3px;
  position: absolute;
  width: 21px;
  height: 21px;
`

export const CrossMarkRed = styled.svg`
  fill: none;
  stroke: #ee4e63;
  stroke-width: 3px;
  position: absolute;
  width: 21px;
  height: 21px;
`;

export const CheckMarkBlack = styled.svg`
  fill: none;
  stroke: black;
  stroke-width: 3px;
  position: absolute;
  width: 21px;
  height: 21px;
`

export const StyledCheckbox = styled.div(
  (props) => `
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: ${props.checked ? '#000' : 'white'};
  border: 1px solid ${props.checked ? '#000' : '#A9A9A9'};
  border-radius: 4px;
  transition: all 150ms;
  ${CheckMarkWhite} {
    visibility: ${props.checked ? 'visible' : 'hidden'}
  }
`)

export const EditorPageGymFactorCalculatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .radio-field{
    .radio-text{
      display: flex;
      align-items: center;

      span{
        font-size: 15px;
        line-height: 19px;
        color: #333;
      }
    }
    .radio-input{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ${CustomMedia.lessThan("sm")`
        justify-content: flex-start;
        margin-top: 10px;
      `}
      select:focus {
        outline: none !important;
        border: 2px solid #0098C9 !important;
      }
    }

    .time-picker-item{
      display: flex;
      flex-direction: column;
    }
    .from-to-text{
      font-size: 14px;
      color: rgba(0, 0, 0, .6);
    }
    .ant-picker{
      border-radius: 4px;
    }
    .ant-picker:hover, .ant-picker-focused{
      border-color: #0098C9;
    }
    .ant-picker-focused{
      box-shadow: 0 0 0 2px rgba(0, 151, 201, 0.47);
    }

    input:focus {
      outline: none !important;
      border: none !important;
    }
  }

  .dropdown-style{
    min-width: 50px;
    min-height: 32px;
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    color: #333;
    font-size: 15px;
    padding: 0 5px;
  }
  .dd-small{
    min-width: 50px;
  }
  .dd-medium{
    min-width: 135px;
  }
  .dd-large{
    min-width: 190px;
  }
  .left-no-radius{
    border-radius: 0 4px 4px 0;
  }
  .price-input-style{
      input{
        min-height: 32px;
        width: 115px;
        padding-left: 40px;
        border-right: 0;
        border-radius: 4px 0 0 4px;
        ${CustomMedia.lessThan("sm")`
          padding-left: 35px;
        `}
      }
      .front-icon-style{
        right: 180px;
        // left: 18px !important;
        // left: 58px;
        // ${CustomMedia.lessThan("sm")`
        //   left: 10px;
        // `}
      }
  }
  .weight-input-style{
    input{
      min-height: 32px;
      width: 95px;
      padding-left: 40px;
      border-right: 0;
      border-radius: 4px 0 0 4px;
    }
    .front-icon-style{
      right: 158px;
        // left: 40px !important;
      // left: 85px;
      // ${CustomMedia.lessThan("sm")`
      //     left: 10px;
      //   `}
    }
  }
  .number-input-style{
    input{
      min-height: 32px;
      width: 70px;
    }
  }
  .textarea-input-style{
  width: 100%;
    textarea{
      min-width: 100%;
    }
  }
`;

export const EditorPageExternalGymCooperationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .radio-field{
    .radio-text{
      display: flex;
      align-items: center;

      span{
        font-size: 15px;
        line-height: 19px;
        color: #333;
      }
    }
    .radio-input{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ${CustomMedia.lessThan("sm")`
        justify-content: flex-start;
        margin-top: 10px;
      `}
      select:focus {
        outline: none !important;
        border: 2px solid #0098C9 !important;
      }
    }

    .time-picker-item{
      display: flex;
      flex-direction: column;
    }
    .from-to-text{
      font-size: 14px;
      color: rgba(0, 0, 0, .6);
    }
    .ant-picker{
      border-radius: 4px;
    }
    .ant-picker:hover, .ant-picker-focused{
      border-color: #0098C9;
    }
    .ant-picker-focused{
      box-shadow: 0 0 0 2px rgba(0, 151, 201, 0.47);
    }

    input:focus {
      outline: none !important;
      border: none !important;
    }
  }

  .dropdown-style{
    min-width: 50px;
    min-height: 32px;
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    color: #333;
    font-size: 15px;
    padding: 0 5px;
  }
  .dd-small{
    min-width: 50px;
  }
  .dd-medium{
    min-width: 135px;
  }
  .dd-large{
    min-width: 190px;
  }
  .left-no-radius{
    border-radius: 0 4px 4px 0;
  }
  .price-input-style{
      input{
        min-height: 32px;
        width: 115px;
        padding-left: 40px;
        border-right: 0;
        border-radius: 4px 0 0 4px;
        ${CustomMedia.lessThan("sm")`
          padding-left: 35px;
        `}
      }
      .front-icon-style{
        right: 180px;
        // left: 18px !important;
        // left: 58px;
        // ${CustomMedia.lessThan("sm")`
        //   left: 10px;
        // `}
      }
  }
  .weight-input-style{
    input{
      min-height: 32px;
      width: 95px;
      padding-left: 40px;
      border-right: 0;
      border-radius: 4px 0 0 4px;
    }
    .front-icon-style{
      right: 158px;
        // left: 40px !important;
      // left: 85px;
      // ${CustomMedia.lessThan("sm")`
      //     left: 10px;
      //   `}
    }
  }
  .number-input-style{
    input{
      min-height: 32px;
      width: 70px;
    }
  }
  .textarea-input-style{
  width: 100%;
    textarea{
      min-width: 100%;
    }
  }
`;
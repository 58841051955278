import React from 'react';
import { CheckboxWrapper, StyledCheckbox, HiddenCheckbox, CheckboxContainer, CheckMarkWhite } from './ComponentStyles';

const Checkbox = ({ onInputChange, name, id, checkboxTextBold, checkboxText, checkboxClickableText, checkboxChecked }) => {
  return (
    <CheckboxWrapper>
      <label onChange={(e) => onInputChange(e, name, 'checkbox')} className='flex items-center'>
        <CheckboxContainer>
          <HiddenCheckbox checked={checkboxChecked} id={id} onChange={() => {}} />
          <StyledCheckbox checked={checkboxChecked}>
            <CheckMarkWhite viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </CheckMarkWhite>
          </StyledCheckbox>
        </CheckboxContainer>
        {checkboxText && <span htmlFor={id} className="label-style"><span className='font-bold'>{checkboxTextBold}</span>{checkboxText}</span>}
        {/* {checkboxClickableText && <a href='#' target="_blank" rel="noreferrer" className="clickable-label">{checkboxClickableText}</a>} */}
      </label>
    </CheckboxWrapper>
  )
}

export default Checkbox;
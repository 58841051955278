import React from 'react';

const SettingsHangfirePage = () => {
    return (
        <div className='flex flex-col w-full'>
            <div className='flex flex-col mb-[30px]'>
                <h3 className='text-black mb-[10px]'>Hangfire (Dev)</h3>
            </div>
            <iframe src="https://api.beta.hotelgyms.com/u2R3tP4-hangfire/" width='100%' height='100%' title="Hangfire (Dev) | HotelGyms.com"></iframe>
        </div>
    );
};

export default SettingsHangfirePage;
import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

axios.interceptors.response.use(
  response => response,
  err => {
    if (err.response.status === 401) {
      //window.location = '/auth/login'
      //navigate to login screen.
    }
    if (err.response.status === 409 || err.response.status === 404) {
      //toast.error(`${err.response.data.status}`)
    } else {
      //toast.error("An error occurred executing the last request!")
    }
    return Promise.reject(err);
  }
);

const defaultOptions = () => {
  return {};
};

const client = {
  get: (url, options = {}) =>
    axiosClient.get(url, { ...defaultOptions(), ...options }),
  post: (url, data, options = {}) =>
    axiosClient.post(url, data, { ...defaultOptions(), ...options }),
  put: (url, data, options = {}) =>
    axiosClient.put(url, data, { ...defaultOptions(), ...options }),
  delete: (url, options = {}) =>
    axiosClient.delete(url, { ...defaultOptions(), ...options })
};

const options = () => {
  const accessToken = sessionStorage.getItem('AccessToken')
  if (accessToken) {
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
  }
  return {};
};

//AppSettings
export const changeAppSettingsAsync = (data) => client.put(`/application-settings`, data, options())
export const getAllAppSettingsAsync = () => client.get(`/application-settings`, options())

//Review Requests
export const getAllReviewRequestedHotels = () => client.get(`/hotels/gymfactor-review-requests`, options())
export const removeReviewRequest = (hotelId) => client.get(`/hotels/${hotelId}/ignore-gymfactor-review-requests`, options())
export const updateHotelReviewRequest = (bookingHotelId) => client.put(`/services/app/Hotels/UpdateHotelRequestReview?bookingHotelId=${bookingHotelId}`)


//Auto Reviews
export const triggerAutoReviews = (data) => client.post(`/gymfactor/trigger-auto-reviews`, data, options())

//Search
export const getAutoSuggest = filter => client.post(`/services/app/Search/AutoComplete`, filter);
// New Search Endpoint
export const searchPageGetHotels = (data) => client.post(`/search`, data);

//URL Shortener
export const urlShortener = (data) => client.post(`/services/app/UrlShortener/CreateLink`, data)

//Provider Hotels
export const getAllProviderHotels = (hotelId) => client.get(`/hotels/${hotelId}/hotelproviders`, options());
export const addProviderHotels = (hotelId, data) => client.post(`/hotels/${hotelId}/hotelproviders`, data, options());
export const updateProviderHotels = (hotelId, id, data) => client.put(`/hotels/${hotelId}/hotelproviders/${id}`, data, options());
export const deleteProviderHotel = (hotelId, id) => client.delete(`/hotels/${hotelId}/hotelproviders/${id}`, options());

//User Experience (User Review)
export const getAllExperiences = (page, pageSize) => client.get(`/gymfactor-experience?page=${page}&pageSize=${pageSize}`, options())
export const getAllHotelExperiences = (hotelId) => client.get(`/hotels/${hotelId}/gymfactor-experiences`, options())
export const updateExperience = (data) => client.put(`/gymfactor-experience`, data, options())
export const deleteExperience = (id) => client.delete(`/gymfactor-experience/${id}`, options())
export const deleteExperienceImage = (id) => client.delete(`/services/app/GymFactorExperience/DeleteExperienceImage?imageId=${id}`, options())

//GymFactor Definitions
export const getGymFactorDefinitions = () => client.get(`/services/app/GymFactor/GetAllGymFactorDefinitions`);

//GymFactor Label
export const getGymFactorByHotelIdAdmin = (hotelId) => client.get(`/services/app/GymFactor/GetGymFactorByHotelIdAdmin?hotelId=${hotelId}`, options())

export const deleteGymFactorImage = (id) => client.delete(`/services/app/GymFactor/DeleteGymFactorImage?imageId=${id}`, options())

//GymFactor Label New Endpoints
export const addGymFactor = (data) => client.post(`/services/app/GymFactor/AddGymFactor`, data, options());
export const updateGymFactor = (data) => client.put(`/services/app/GymFactor/UpdateGymFactor`, data, options());
export const updateAllowScoreOverwrite = (data) => client.put(`/services/app/GymFactor/UpdateAllowScoreOverwrite`, data, options());

//Providers
export const getAllProviders = () => client.get(`/services/app/Providers/GetAllProviders`)

//Image
export const updateIsGalleryImage = (imageId, data) => client.put(`services/app/Image/UpdateGalleryImage?id=${imageId}`, data, options())
export const updateIsProfileImage = (imageId, data) => client.put(`services/app/Image/UpdateProfileImage?id=${imageId}`, data, options())

export const editImageProvider = (data) => client.post(`/services/app/Image/EditImageProvider`, data, options())

//Hotel Information: social media, yearBuild, lastRenovation etc.
export const updateHotel = (data) => client.put(`/services/app/Hotels/UpdateHotel`, data, options())

//GymFactorDetail
export const getGymFactorDetails = (hotelId) => client.get(`/services/app/GymFactorDetail/GetGymFactorDetails?hotelId=${hotelId}`, options());
export const addGymFactorDetail = (data) => client.post(`/services/app/GymFactorDetail/AddGymFactorDetail`, data, options());
export const updateGymFactorDetail = (data) => client.put(`/services/app/GymFactorDetail/UpdateGymFactorDetail`, data, options());

//External Gyms
export const getExternalGyms = (latitude, longitude) => client.get(`/services/app/GymDetails/GetExternalGyms?Latitude=${latitude}&Longitude=${longitude}`, options())
export const addExternalGym = (placeId) => client.post(`/services/app/GymDetails/AddExternalGym?placeId=${placeId}`);

//New Cooperations
export const getExternalGymCooperationsAdmin = (bookingHotelId) => client.get(`/fitness/external-gym-cooperation-admin?bookingHotelId=${bookingHotelId}`, options())

//Hotel Gym Cooperation
export const addHotelGymCooperation = (data) => client.post(`/services/app/Hotels/AddHotelGymCooperation`, data, options())
export const updateHotelGymCooperation = (data) => client.put(`/services/app/Hotels/UpdateHotelGymCooperation`, data, options())

//Statistics (Dashboard)
export const getStatistics = () => client.get(`/services/app/Rating/GetRatingsReport`, options())
import React, { useState, useEffect } from 'react';
import Checkbox from '../components/Checkbox';
import { webAppSettings } from '../components/constants';
import { changeAppSettingsAsync, getAllAppSettingsAsync } from '../redux.js/api';
import ToastNotification from '../components/ToastNotification';

const SettingsWebAppPage = () => {
    const [appSettings, setAppSettings] = useState(false)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchAllAppSettings = async () => {
            setLoading(true)
            const { data: { result } } = await getAllAppSettingsAsync();
            setAppSettings(result)
            setLoading(false)
        }
        fetchAllAppSettings()
    }, [])

    const handleChangeSetting = async (e, name, type) => {
        setLoading(true)
        let value
        const field = name;
        if (field === webAppSettings.MinimumBookingRating) {
            value = e.target.value;
        }
        else {
            value = e.target.checked ? "true" : "false"
        }
        const index = appSettings.findIndex(x => x.name === field)
        let newAppSettings = [...appSettings]
        newAppSettings[index] = { ...newAppSettings[index], value: value }
        setAppSettings(newAppSettings)
        try {
            await changeAppSettingsAsync({ name: field, value: value })
            ToastNotification('success', 'Settings changed successfully!')
        }
        catch (err) {
            ToastNotification('error', 'Oops! Something went wrong. Please try again later.')
            setAppSettings(appSettings)
        }
        setLoading(false)
    }
    return (
        <>
            {loading &&
                <div className="text-center py-2">
                    <span className="sr-only">Loading...</span>
                </div>
            }
            {appSettings &&
                <div className='flex flex-col w-full'>
                    <div className='flex flex-col mb-[30px]'>
                        <h3 className='text-black mb-[10px]'>Google Places</h3>
                        <div className='flex flex-col px-[12px] py-[10px] border rounded-[10px] w-full bg-gray-50'>
                            <div className='flex w-full border-b pb-[10px] mb-[10px]'>
                                <Checkbox
                                    onInputChange={handleChangeSetting}
                                    name={webAppSettings.AddHotelReviewAndMaps}
                                    id={`${webAppSettings.AddHotelReviewAndMaps}-checkbox`}
                                    checkboxText='Add new Hotel Review'
                                    checkboxChecked={appSettings && appSettings.find(x => x.name === webAppSettings.AddHotelReviewAndMaps).value === "true" ? true : false}
                                />
                            </div>
                            <div className='flex w-full border-b pb-[10px] mb-[10px]'>
                                <Checkbox
                                    onInputChange={handleChangeSetting}
                                    name={webAppSettings.AllowHotelReviewUpdates}
                                    id={`${webAppSettings.AllowHotelReviewUpdates}-checkbox`}
                                    checkboxText='Update Hotel Reviews'
                                    checkboxChecked={appSettings && appSettings.find(x => x.name === webAppSettings.AllowHotelReviewUpdates).value === "true" ? true : false}
                                />
                            </div>
                            <div className='flex w-full border-b pb-[10px] mb-[10px]'>
                                <Checkbox
                                    onInputChange={handleChangeSetting}
                                    name={webAppSettings.AllowGoogleGymCooperationData}
                                    id={`${webAppSettings.AllowGoogleGymCooperationData}-checkbox`}
                                    checkboxText='Add/Update Gym Cooperation'
                                    checkboxChecked={appSettings && appSettings.find(x => x.name === webAppSettings.AllowGoogleGymCooperationData).value === "true" ? true : false}
                                />
                            </div>
                            <div className='flex w-full'>
                                <Checkbox
                                    onInputChange={handleChangeSetting}
                                    name={webAppSettings.AllowNearbyGymsInformationUpdates}
                                    id={`${webAppSettings.AllowNearbyGymsInformationUpdates}-checkbox`}
                                    checkboxText='Update Nearby Gym Reviews'
                                    checkboxChecked={appSettings && appSettings.find(x => x.name === webAppSettings.AllowNearbyGymsInformationUpdates).value === "true" ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col mb-[30px]'>
                        <h3 className='text-black mb-[10px]'>Tripadvisor Content</h3>
                        <div className='flex flex-col px-[12px] py-[10px] border rounded-[10px] w-full bg-gray-50'>
                            <div className='flex w-full border-b pb-[10px] mb-[10px]'>
                                <Checkbox
                                    onInputChange={handleChangeSetting}
                                    name={webAppSettings.AllowTripAdvisorScore}
                                    id={`${webAppSettings.AllowTripAdvisorScore}-checkbox`}
                                    checkboxText='Add/Update Tripadvisor Scores'
                                    checkboxChecked={appSettings && appSettings.find(x => x.name === webAppSettings.AllowTripAdvisorScore).value === "true" ? true : false}
                                />
                            </div>
                            <div className='flex w-full'>
                                <Checkbox
                                    onInputChange={handleChangeSetting}
                                    name={webAppSettings.AllowTripAdvisorTextReviews}
                                    id={`${webAppSettings.AllowTripAdvisorTextReviews}-checkbox`}
                                    checkboxText='Add/Update Tripadvisor Text Reviews'
                                    checkboxChecked={appSettings && appSettings.find(x => x.name === webAppSettings.AllowTripAdvisorTextReviews).value === "true" ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col mb-[30px]'>
                        <h3 className='text-black mb-[10px]'>Maps</h3>
                        <div className='flex flex-col px-[12px] py-[10px] border rounded-[10px] w-full bg-gray-50'>
                            <div className='flex w-full'>
                                <Checkbox
                                    onInputChange={handleChangeSetting}
                                    name={webAppSettings.EnablePremiumMaps}
                                    id={`${webAppSettings.EnablePremiumMaps}-checkbox`}
                                    checkboxText='Enable Premium Maps'
                                    checkboxChecked={appSettings && appSettings.find(x => x.name === webAppSettings.EnablePremiumMaps).value === "true" ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default SettingsWebAppPage;